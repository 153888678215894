import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import PricingCards from './cards/PricingCards';
import { BillingCycle } from './Prices';
import styles from './PricingContent.module.scss';
import PricingTable from './table/PricingTable';

type PlanFeatureName = [string, ReactNode | undefined];
type PlanFeatureValue = string | boolean;
type PlanFeatureRow = [
  PlanFeatureName,
  PlanFeatureValue,
  PlanFeatureValue,
  PlanFeatureValue,
  PlanFeatureValue,
];
type PlanFeatureSection = [string, PlanFeatureRow[]];
export type PlanFeatures = PlanFeatureSection[];

const PLAN_FEATURES: PlanFeatures = [
  [
    'Usage',
    [
      [
        ['Users', undefined],
        'Up to 10',
        'Unlimited',
        'Unlimited',
        'At least 100',
      ],
      [
        [
          'Guests',
          'Guests are free on all plans, but count towards the 10 user limit on the Free plan',
        ],
        false,
        '3 per user',
        '5 per user',
        'Unlimited',
      ],
      [
        [
          'Attachments',
          <>
            Upload limit per
            <br />
            attachment
          </>,
        ],
        '10MB',
        '25MB',
        '100MB',
        'Custom',
      ],
      [
        ['Version History', 'Access and restore past versions of your content'],
        '90 days',
        '365 days',
        'Unlimited',
        'Unlimited',
      ],
    ],
  ],
  [
    'Essentials',
    [
      [
        [
          'Posts and Topics',
          'Unlimited content and organization for all your team knowledge',
        ],
        'Unlimited',
        'Unlimited',
        'Unlimited',
        'Unlimited',
      ],
      [
        [
          'Realtime Collaboration',
          'Simultaneous editing and commenting with your team',
        ],
        true,
        true,
        true,
        true,
      ],
      // [['User Groups', undefined], true, true, true, true],
      [['Templates', undefined], true, true, true, true],
      [
        [
          'Verification',
          'Require regular review on certain posts to ensure accuracy and trust',
        ],
        true,
        true,
        true,
        true,
      ],
      [
        [
          'Unified Search',
          'Search not just from Slab, but also from all your integrated tools',
        ],
        true,
        true,
        true,
        true,
      ],
    ],
  ],
  [
    'Advanced',
    [
      [
        [
          'Private Topics',
          <>
            Access control for
            <br />
            sensitive published
            <br />
            content
          </>,
        ],
        false,
        true,
        true,
        true,
      ],
      [
        ['Custom Domains', 'Use any.company.com instead of company.slab.com'],
        false,
        false,
        true,
        true,
      ],
      [
        [
          'Usage Analytics',
          'Insights into your most popular content and productive contributors',
        ],
        '30 days',
        '90 days',
        '365 days',
        'Unlimited',
      ],
    ],
  ],
  [
    'Security',
    [
      [['Single Sign On', 'Google Workplace or Slack'], true, true, true, true],
      [
        [
          'SAML-based SSO',
          <>
            Okta, OneLogin, or
            <br />
            Azure AD
          </>,
        ],
        false,
        false,
        true,
        true,
      ],
      [
        ['SCIM Provisioning', 'With Okta integration'],
        false,
        false,
        true,
        true,
      ],
      // [['Audit Logs', undefined], false, false, false, true],
    ],
  ],
  [
    'Integrations',
    [
      [['Standard', undefined], '10', 'Unlimited', 'Unlimited', 'Unlimited'],
      [
        [
          'Premium',
          <>
            Our most advanced
            <br />
            integrations.
            <Spacing top={1}>
              <Link to="/integrations/premium/" target="_blank">
                <Text underline>See the full list</Text>
              </Link>
            </Spacing>
          </>,
        ],
        false,
        false,
        '3',
        'Unlimited',
      ],
      [
        [
          'API',
          <>
            Build custom
            <br />
            integrations.
            <Link
              to="https://studio.apollographql.com/public/Slab/home"
              target="_blank"
            >
              <Text underline>Read docs</Text>
            </Link>
          </>,
        ],
        false,
        false,
        'GraphQL',
        'GraphQL',
      ],
      [
        [
          'Webhooks',
          <>
            Integrate custom
            <br />
            workflows.
            <Link to="https://the.slab.com/public/a9evxmgd" target="_blank">
              <Text underline>Read docs</Text>
            </Link>
          </>,
        ],
        false,
        false,
        true,
        true,
      ],
      // [['API Access', undefined], false, false, 'Webhooks', 'GraphQL'],
    ],
  ],
  [
    'Support',
    [
      [
        ['Support Level', undefined],
        'Basic',
        'Standard',
        'Priority',
        'Dedicated',
      ],
      [['Uptime SLA', undefined], false, false, true, true],
    ],
  ],
];

interface PricingContentState {
  billingCycle: BillingCycle;
}

class PricingContent extends React.Component<unknown, PricingContentState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: PricingContentState = {
    billingCycle: 'annually',
  };

  handleCycleChange = (billingCycle: BillingCycle) => {
    this.setState({ billingCycle });
  };

  render() {
    const pricingControls = {
      ...this.state,
      onCycleChange: this.handleCycleChange,
    };

    return (
      <div>
        <div className={styles.table}>
          <PricingTable
            planFeatures={PLAN_FEATURES}
            pricingControls={pricingControls}
          />
        </div>
        <div className={styles.cards}>
          <PricingCards
            planFeatures={PLAN_FEATURES}
            pricingControls={pricingControls}
          />
        </div>
      </div>
    );
  }
}

export default PricingContent;
